import UIUX from "./UIUX";
import Title2 from "./Title2";
import Box from "./images/box.png";
import Cont from "./images/contact.png";
import Collage from "./images/collage.png";
import Logos from "./Logos";
import Posters from "./Posters";
import Contact from "./Contact";

function UXFolio() {
  return (
    <div>
      <div>
        <Title2 />
        <p
          className="tag-font"
          style={{ opacity: "1.0", cursor: "pointer", color: "#f1e69a" }}
        >
          " As a UX designer currently in the US, I specialize in brand design,
          mobile/web UX, and User Interface. <br />
          <br /> With experience in freelancing for diverse companies and
          startups, I create engaging and intuitive digital experiences. "
        </p>
        <p
          className="tag-font"
          style={{
            opacity: "1.0",
            cursor: "pointer",
            color: "white",
            marginTop: "30px",
          }}
        >
          ... scroll down to see my work and know more about me ...
        </p>
        <img src={Box} alt="Box" className="responsive-img" />
      </div>
      <p
        className="layout-title"
        style={{
          color: "white",
          opacity: 1,
          textAlign: "center",
          fontSize: "200%",
        }}
      >
        UI UX Case Studies
      </p>
      <UIUX />
      <br />
      <br />
      <p
        className="layout-title"
        style={{
          color: "white",
          opacity: 1,
          textAlign: "center",
          fontSize: "200%",
        }}
      >
        Other Design related work
      </p>
      <p style={{ color: "white" }}>
        In addition to the above mentioned UI/UX Case Studies, I also like to
        work on other design stuff like making posters, creating logos, brand
        redesign etc. <br />
        <br /> Working as a freelancer and also having interned at an ad agency
        has given me the opportunity to learn from a lot of experts in the field
        and clients whose inputs helped me to understand user requirements.
        Check out the<a  className="link-dp" href="https://www.belohith.com/creative" target="_blank">Creative</a>section on my website that has more examples of
        my work or my<a  className="link-dp" href="hhttps://www.behance.net/belohith" target="_blank" >Behance</a>profile.{" "}
      </p>
      <img src={Collage} alt="Collage" className="responsive-img-1" />
      <br />
      <img src={Cont} alt="Contact" className="responsive-img" />
      <p style={{ color: "white" }}>
       If you'd like to know more about me, check out the<a  className="link-dp" href="https://www.belohith.com/about" target="_blank">About</a>Section on my website. Or<a  className="link-dp" href="https://www.belohith.com/" target="_blank">view the complete website here.</a><br />
        <br /> Would you like to connect?
      </p>
      <br/>
      <Contact />
    </div>
  );
}

export default UXFolio;
